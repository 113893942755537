<template>
    <transition name="fade">
        <div class="modalUserDetail">
            <div class="sidebar-header">
                <div class="header-detail__user-left">
                    <button class="btn btn-warning">パスワード初期化</button>
                    <button class="btn btn-info ml-4">パスワード初期化</button>
                </div>
                <div class="header-detail__user-right">
                    <button class="btn btn-danger"><i class="fas fa-trash-alt"></i> パスワード初期化</button>
                </div>
            </div>
            <div class="sidebar-main">
                <div class="sidebar-list__item">
                    <div class="user-flag" v-if="dataUserDetail.account_lock_flag == messages.DISABLE_USER_FLAG_TRUE">アカウントロック中</div>
                    <div v-else></div>
                    <div class="form-group d-flex justify-content-between">
                        <div class="form-subgroup d-flex flex-column align-items-start mr-4">
                            <label for="">登録</label>
                            <p class="mb-0">{{ dataUserDetail.created_at | timestamp }}</p>
                        </div>
                        <div class="form-subgroup d-flex flex-column align-items-start">
                            <label for="">更新情報</label>
                            <p class="mb-0 align-items-end">{{ dataUserDetail.updated_at | timestamp }}</p>
                        </div>
                    </div>
                </div>
                <div class="sidebar-list__item">
                    <div class="form-group">
                        <label for="">無効</label>
                        <input type="checkbox" />
                    </div>
                    <div class="form-group d-flex justify-content-between">
                        <div class="form-subgroup d-flex flex-column align-items-start mr-4">
                            <label for="">最終ログイン日時</label>
                            <p class="mb-0">{{ dataUserDetail.login_last_datetime | timestamp }}</p>
                        </div>
                        <div class="form-subgroup d-flex flex-column align-items-start">
                            <label for="">更新情報</label>
                            <p class="mb-0 align-items-end">{{ dataUserDetail.login_fail_count }}</p>
                        </div>
                    </div>
                </div>
                <div class="sidebar-list__item">
                    <div class="form-group">
                        <label for="">メールアドレス</label>
                        <p class="mb-0 align-items-end">{{ dataUserDetail.user_mail }}</p>
                    </div>
                    <div class="form-group d-flex justify-content-between">
                        <div class="form-subgroup d-flex flex-column align-items-start mr-4">
                            <label for="">パスワード初期化日時</label>
                            <p class="mb-0">{{ dataUserDetail.password_init_datetime | timestamp }}</p>
                        </div>
                        <div class="form-subgroup d-flex flex-column align-items-start">
                            <label for="">アカウントロック日時</label>
                            <p class="mb-0 align-items-end">{{ dataUserDetail.account_lock_datetime | timestamp }}</p>
                        </div>
                    </div>
                </div>
                <div class="sidebar-list__item">
                    <div class="form-group">
                        <label for="">ユーザ名</label>
                        <p class="mb-0 align-items-end">{{ dataUserDetail.user_name }}</p>
                    </div>
                </div>
                <div class="sidebar-list__item">
                    <div class="form-group">
                        <label for="">ユーザ表示名</label>
                        <p class="mb-0 align-items-end">{{ dataUserDetail.user_name_disp }}</p>
                    </div>
                </div>
                <div class="sidebar-list__item">
                    <div class="form-group">
                        <label for="">職種</label>
                        <p class="mb-0 align-items-end">{{ dataUserDetail.user_occupation }}</p>
                    </div>
                </div>
                <div class="sidebar-list__item">
                    <div class="form-group">
                        <label for="">システム権限区分</label>
                        <p class="mb-0 align-items-end">{{ dataUserDetail.user_sys_auth_div }}</p>
                    </div>
                </div>
            </div>
            <div class="sidebar-footer">
                <div class="footer-detail__user-left">
                    <button class="btn btn-secondary" @click="closeModal(show = false)"><i class="fas fa-undo-alt"></i> キャンセル</button>
                </div>
                <div class="footer-detail__user-right">
                    <button class="btn btn-primary"><i class="fas fa-save"></i> 保存</button>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import moment from "moment";
import { MESSAGE } from "@/common/constants";
export default {
    name: "Modal",
    props: ["dataUserDetail"],
    data() {
        return {
            messages: MESSAGE
        }
    },
    filters: {
        timestamp: function (data) {
            return moment(data).format("YYYY-MM-DD")
        }
    },
    methods: {
        closeModal: function (data) {
            this.$emit("closeModal", data);
        }
    }
}
</script>
<style lang="scss" scoped>
    .modalUserDetail {
        width: 50%;
        height: 100%;
        position: absolute;
        z-index: 11;
        top: 0;
        right: 0;
        background: white;
        transition: all .5s;
        -webkit-transition: all .5s;
        transition-timing-function: ease-in-out;

        .sidebar-header {
            display: flex;
            justify-content: space-between;
            padding: 15px;
            border-bottom: 1px solid #ccc
        }

        .sidebar-footer {
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            padding: 15px;
            border-top: 1px solid #ccc
        }

        .sidebar-main .sidebar-list__item {
            padding: 15px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        .sidebar-main .sidebar-list__item .user-flag {
            background-color: #ff6a79;
            padding: 5px 15px;
        }

        .sidebar-main .sidebar-list__item .form-group input {
            width: 100%;
            background-color: #e9e9e9;
            padding: 5px 7px;
            outline: none;
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: all .5s;
        -webkit-transition: all .5s;
        transform: translate(100%, 0);
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        transition: all .5s;
        -webkit-transition: all .5s;
        opacity: 0;
        transform: translate(100%, 0);
    }
</style>
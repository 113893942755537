<template>
    <div class="p-2 list-data">
        <header class="align-items-center pb-3 d-flex flex-row-reverse"><button class="btn btn-primary"><i class="fas fa-plus"></i> 追加</button></header>
        <div class="data-table">
            <table class="table">
                <colgroup><col class=""><col class=""><col class=""><col class=""><col class=""><col class=""><col class=""><col class=""></colgroup>
                <thead>
                    <tr>
                        <th>無効</th>
                        <th>メールアドレス</th>
                        <th>ユーザ名</th>
                        <th>ユーザ表示名</th>
                        <th>職種</th>
                        <th>システム権限区分</th>
                        <th>最終ログイン日時</th>
                        <th>ログイン失敗回数</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="dataUser in listUser" v-bind:key="dataUser.id" v-on:click="userDetail(dataUser.id)">
                        <td v-if="dataUser.disable_flg === messages.DISABLE_USER_FLAG_FALSE"><input type="radio" /></td>
                        <td v-if="dataUser.disable_flg === messages.DISABLE_USER_FLAG_TRUE"><input type="radio" disabled /></td>
                        <td>{{ dataUser.user_mail }}</td>
                        <td>{{ dataUser.user_name }}</td>
                        <td>{{ dataUser.user_name_disp }}</td>
                        <td v-if="dataUserOccupation">{{ dataUserOccupation[dataUser.user_occupation]}}</td>
                        <td v-if="dataUserRoles">{{ dataUserRoles[dataUser.user_sys_auth_div]}}</td>
                        <td>{{ dataUser.login_last_datetime }}</td>
                        <td>{{ dataUser.login_fail_count }}</td>
                    </tr>
                </tbody>
            </table>
            <Pagination :total-page="totalPage" :current-page="currentPage" @onHandleChangePage="onHandleChangePage"/>
            <Modal v-if="show" v-click-outside="handleFocusOut" :dataUserDetail="dataUserDetail" @closeModal="handleFocusOut" />
        </div>
    </div>
</template>
<script>
import UserManagementService from "@/services/api/AdminRepository";
import { MESSAGE } from "@/common/constants";
import Pagination from "@/components/common/Pagination";
import { SUCCESS } from "@/constants";
import { mapMutations } from "vuex";
import Modal from "@/components/common/Modal";

export default {
    name: "list",
    components: { Pagination, Modal },
    data() {
        return {
            show: false,
            listUser: [],
            dataUserDetail: [],
            dataUserOccupation: [],
            dataUserRole: [],
            messages: MESSAGE,
            totalPage: 1,
            currentPage: 1,
            perPage: 10
        };
    },
    created() {
        this.userOccupations();
        this.userRoles();
        this.getUsers();
    },
    methods: {
        getUsers: function() {
            this.setIsLoading(true)
            UserManagementService.listUser(this.currentPage, this.perPage)
                .then((res) => {
                    window.scrollTo({
                        behavior: "smooth",
                        top: 0
                    });
                    const {
                        data,
                        status
                    } = res

                    this.setIsLoading(false)
                    if (status !== SUCCESS) return;
                    this.listUser = data.data;
                    this.totalPage = data.last_page;
                    this.currentPage = data.current_page;
                })
        },
        userOccupations: function() {
            UserManagementService.userOccupation()
                .then((res) => {
                    this.dataUserOccupation = res.data;
                })
        },
        userRoles: function() {
            UserManagementService.userRole()
                .then((res) => {
                    this.dataUserRoles = res.data;
                })
        },
        userDetail: function(event) {
            if (event) {
                UserManagementService.getUserDetail(event)
                    .then((res) => {
                        this.dataUserDetail = res.data.data;
                    })
                this.show = true;
            }
        },
        handleFocusOut: function() {
            this.show = false;
        },
        onHandleChangePage(pageNumber) {
            this.currentPage = pageNumber;
            this.getUsers();
        },
        ...mapMutations({
            setIsLoading: "common/setIsLoading"
        })
    }
}
</script>